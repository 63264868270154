<!--If there is an image in the item, show the image, empty placeholder or any given ng-content otherwise-->
<!--Add the general category-->

<div class="catalog-item" [ngClass]="{ 'disabled': disabled }">
    <ng-container
            [ngTemplateOutlet]="edit ? editMainModule :
            woodType ? editWoodType :
            regularCatalogItem"
    ></ng-container>

    <ng-template #editMainModule>
        @if (iconValue) {
            <img crossorigin="anonymous"
                 [src]="iconValue.url"
                 (error)="handleImageError(iconValue.id)"
                 class="catalog-icon">
        } @else {
            <ng-content></ng-content>
        }
    </ng-template>

    <ng-template #editWoodType>
        @if (isWoodType && woodType) {
            <img crossorigin="anonymous"
                 [src]="'assets/icons/' + woodType + '.jpg'"
                 class="catalog-icon">
        } @else {
            <ng-content></ng-content>
        }
    </ng-template>

    <ng-template #regularCatalogItem>
        @if (item?.catalogIcon) {
            <img crossorigin="anonymous"
                 [src]="item.catalogIcon.url"
                 (error)="handleImageError(item.catalogIcon.id)"
                 class="catalog-icon" [class.selected]="itemSelected">
        } @else {
            <ng-content></ng-content>
        }
    </ng-template>

    @if (itemSelected) {
        <div class="icon-padding">
            <svg-icon [src]="selectedIconSrc" class="icon-check">
            </svg-icon>
        </div>
    }
</div>
