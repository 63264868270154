@if (loadingFinished && !plpMode) {
    <div class="catalog-container">
        <!--Add the general category-->
        <ng-container
                [ngTemplateOutlet]="showCategories ? regularCatalog :
            showEditTower ? editTowerCatalog :
            noAttachmentPoint"
        ></ng-container>
        <ng-template #regularCatalog>
            @if (showDeleteModule) {
                <div class="category-container">
                    <div class="font-h3 catalog-header">{{ 'app.catalog.generalCatalogCategory' | translate }}</div>
                    <!--No module item-->
                    <div class="catalog-items-container">
                        <app-catalog-item
                                (click)="clickNoModuleItem()"
                                class="catalog-item"
                                gaTag="catalog.remove-button"
                        >
                            <svg-icon [src]="trashIconSrc" class="icon-remove"></svg-icon>
                        </app-catalog-item>
                    </div>
                </div>
            }
            <!--Loop over all the categories-->
            @for (catalogCategory of catalogCategories; track catalogCategory) {
                <div class="category-container">
                    <!--Title of the category-->
                    <div class="font-h3 catalog-header">{{ catalogCategory.title | translate }}</div>
                    <!--All the items-->
                    <div class="catalog-items-container">
                        <!--Create catalog item for each-->
                        <!--Note that the ngStyle calculation does *not* seem to trigger change detection-->
                        <!--Doing it like this makes it so that we prevent some code duplication as the column/row for -->
                        <!--orientation is set in scss file (not ideal, but justified here).-->
                        @for (catalogItem of catalogCategory.variants; track catalogItem; let i = $index) {
                            <app-catalog-item
                                    [ngStyle]="{'grid-row': (i % 2 + 1), 'grid-column': (i % 4 + 1)}"
                                    (click)="clickCatalogItem(catalogItem.id)"
                                    [item]="catalogItem"
                                    [itemSelected]="latestSelected | itemIsSelected : catalogItem.id"
                                    class="catalog-item"
                                    gaTag="catalog.variant"
                            ></app-catalog-item>
                        }
                        @for (catalogItem of catalogCategory.invalidVariants; track catalogItem; let i = $index) {
                            <app-catalog-item
                                    [ngStyle]="{'grid-row': (i % 2 + 1), 'grid-column': (i % 4 + 1)}"
                                    [item]="catalogItem"
                                    [itemSelected]="latestSelected | itemIsSelected : catalogItem.id"
                                    [disabled]="true"
                                    class="catalog-item"
                                    gaTag="catalog.variant.invalid"
                            ></app-catalog-item>
                        }
                    </div>
                </div>
            }
        </ng-template>
        <ng-template #editTowerCatalog>
            @for (catalogAttributes of catalogAttributesList; track catalogAttributes) {
                @for (attribute of catalogAttributes.values; track attribute) {
                    <div class="category-container">
                        <!--Title of the category-->
                        <!--                FUNCTION IN TEMPLATE-->
                        <div class="font-h3 catalog-header">{{ ("attribute." + attribute.key) | translate }}</div>
                        <!--All the items-->
                        <div class="catalog-items-container">
                            <!--Create catalog item for each-->
                            <!--Note that the ngStyle calculation does *not* seem to trigger change detection-->
                            <!--Doing it like this makes it so that we prevent some code duplication as the column/row for -->
                            <!--orientation is set in scss file (not ideal, but justified here).-->
                            @for (iconValue of attribute.values; track iconValue; let i = $index) {
                                <app-catalog-item
                                        [ngStyle]="{'grid-row': (i % 2 + 1), 'grid-column': (i % 4 + 1)}"
                                        (click)="clickCatalogAttribute(attribute.key, iconValue.key)"
                                        [iconValue]="iconValue.value"
                                        [edit]="true"
                                        [itemSelected]="filterSetting | mapContainsValuePipe :  attribute.key : iconValue.key"
                                        class="catalog-item"
                                        gaTag="catalog.edit-tower"
                                ></app-catalog-item>
                            }
                        </div>
                    </div>
                }
            }
            @if (showDeleteModule) {
                <div class="category-container">
                    <div class="font-h3 catalog-header">{{ 'app.catalog.generalCatalogCategory' | translate }}</div>
                    <!--No module item-->
                    <div class="catalog-items-container">
                        <app-catalog-item
                                (click)="clickNoModuleItem()"
                                class="catalog-item"
                                gaTag="catelog.remove-button">
                            <svg-icon [src]="trashIconSrc" class="icon-remove"></svg-icon>
                        </app-catalog-item>
                    </div>
                </div>
            }
        </ng-template>

        <ng-template #noAttachmentPoint>
            <!--If there is no attachment point selected we display a title and text, if it is set in the scope -->
            <div class="category-container">
                <h2 class="font-h2 catalog-title">{{ 'app.catalog.title' | translate }}</h2>
                <p class="font-body1 catalog-text" [innerHTML]="'app.catalog.text' | translate"></p>
            </div>
        </ng-template>
    </div>
}
