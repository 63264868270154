import {Injectable} from '@angular/core';
import {
  calculateInstallationPrice,
  Configuration,
  FatModuleVariant,
  ModulePlacement,
  WoodType
} from "@ess/jg-rule-executor";
import {BehaviorSubject, Observable} from "rxjs";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {DEFAULT_CURRENCY, DEFAULT_LOCALE} from "@src/app/constants";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {Icon} from "@src/app/library/components/icon";

@Injectable({
  providedIn: 'root'
})
export class PriceCalculatorService {
  private _currentPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);
  private _installationServiceChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _latestBasePrice: number = 0;
  private _lastKnownConfiguration: Configuration = undefined;

  constructor(
    private _dataService: InitDataService,
    private _notificationService: NotificationService
  ) {
  }

  public currentPrice$(): Observable<number> {
    return this._currentPrice$.asObservable();
  }

  public installationServiceChanged$(): Observable<boolean> {
    return this._installationServiceChanged$.asObservable();
  }

  /**
   * Changes the current configuration base price and sets the correct price in the observable to notify everybody
   */
  public configurationChanged(configuration: Configuration): void {
    this._lastKnownConfiguration = configuration;
    this._checkInstallationValidity();
    this._setBasePrice();
    this._emitCurrentPrice();
    this.installationServiceChange(configuration.installationService);
  }

  /**
   * Checks if installation is still available for this configuration, unsets it if appropriate and informs the user.
   */
  private _checkInstallationValidity(): void {
    // Check if installation available before we calculate
    if (!this.installationServiceAvailableForConfiguration(this._lastKnownConfiguration) && this._lastKnownConfiguration?.installationService) {
      this._lastKnownConfiguration.installationService = false;
      this._installationServiceChanged$.next(false);
      // Shoot message
      this._notificationService.showNotification("app.installation_service.disabled", Icon.warn, NotificationCardType.warning);
    }
  }

  /**
   * Sets base price for the given configuration
   */
  private _setBasePrice(): void {
    const variants = this._dataService.allVariantsForWoodType(this._lastKnownConfiguration.configurationPlacement.woodType);
    this._latestBasePrice = this. _calculateBasePrice(this._lastKnownConfiguration, variants);
  }

  /**
   * Calculates the base price for a given configuration from a set of variants
   */
  private _calculateBasePrice(configuration: Configuration, variants: FatModuleVariant[]): number {
    return configuration.configurationPlacement.placements?.map(x => x.variantId)
      .map(varId => variants.find(x => x.variant.id === varId))
      .map(mv => mv && mv.variant.price ? mv.variant.price : 0)
      .reduce((c: number, n: number) => c + n, 0);
  }

  /**
   * Sets the installation service variables accordingly and notifies user if something changed
   */
  public installationServiceChange(newValue: boolean): void {
    if (this._lastKnownConfiguration?.installationService !== newValue) {
      if (!newValue || (newValue && this.installationServiceAvailableForConfiguration())) {
        this._lastKnownConfiguration.installationService = newValue;
        this._installationServiceChanged$.next(newValue);
        this._emitCurrentPrice();
      } else if (newValue) {
        // Should not happen due to checks in other components
        this._notificationService.showNotification("app.installation_service.notChanged", Icon.warn, NotificationCardType.warning);
      }
    } else if (this._installationServiceChanged$.value !== newValue) {
      this._installationServiceChanged$.next(newValue);
    }
  }

  /**
   * Current state of installation service
   */
  public getInstallationService(): boolean {
    return this._lastKnownConfiguration?.installationService;
  }

  /**
   * Emits the currently known price
   */
  private _emitCurrentPrice(): void {
    if (this._lastKnownConfiguration?.installationService) {
      this._currentPrice$.next(this._roundPrice(this._latestBasePrice + this.getCurrentInstallationPrice()));
    } else {
      this._currentPrice$.next(this._roundPrice(this._latestBasePrice));
    }
  }

  /**
   * Returns if the installation service is available for the retailer for some configurations
   */
  public installationServiceAvailableForRetailer(): boolean {
    return this._dataService.currentRetailerOptions?.installationPrices.size > 0;
  }

  /**
   * Returns if the installation service is available for the current configuration (including wood type)
   */
  public installationServiceAvailableForConfiguration(configuration?: Configuration): boolean {
    const config = configuration ?? this._lastKnownConfiguration;
    const [cheapestWoodType, cheapestBasePrice] = config ? this._cheapestWoodType(config) : [undefined, undefined];
    const mbPrices = this._dataService.currentRetailerOptions?.installationPrices?.get(cheapestWoodType);
    return !!((config) && mbPrices && this._getInstallationPrice(cheapestBasePrice, cheapestWoodType));
  }

  /**
   * Determines the cheapest wood type for the configuration
   */
  private _cheapestWoodType(configuration: Configuration): [WoodType, number] {

    const allVarsGrenen = this._dataService.allVariantsForWoodType(WoodType.grenen)
    const allVarsDouglas = this._dataService.allVariantsForWoodType(WoodType.douglas)

    // check for missing prices (if the woodType is not available return 'true' to indicate missing prices)
    const grenenMissing = this._isWoodTypeAvailable(WoodType.grenen) ?
      this._hasMissingPrices(
        configuration.configurationPlacement.placements,
        allVarsGrenen
      ) : true;

    const douglasMissing = this._isWoodTypeAvailable(WoodType.douglas) ?
      this._hasMissingPrices(
        configuration.configurationPlacement.placements,
        allVarsDouglas
      ) : true;

    if (!grenenMissing && !douglasMissing) {
      const grenenPrice = this._calculateBasePrice(configuration, allVarsGrenen);
      const douglasPrice = this._calculateBasePrice(configuration, allVarsDouglas);
      if (grenenPrice <= douglasPrice) {
        return [WoodType.grenen, grenenPrice];
      } else {
        return [WoodType.douglas, douglasPrice];
      }
    } else {
      return [this._dataService.currentWoodType,
        this._calculateBasePrice(configuration, this._dataService.allVariantsForWoodType(this._dataService.currentWoodType))
      ]
    }
  }

  /**
   * Checks the availability of the provided woodType
   * Note: If woodTypesAvailable is not defined, the basePrice will always be calculated with the currently selected woodType.
   * @param woodType
   * @private
   */
  private _isWoodTypeAvailable(woodType: WoodType): boolean {
    return !!this._dataService.currentRetailerOptions?.woodTypesAvailable?.find(w => w.woodType === woodType && w.available === true)
  }

  private _hasMissingPrices(placements: ModulePlacement[], allWoodTypeVariants: FatModuleVariant[]): boolean {
    return placements.filter(value =>
      allWoodTypeVariants.filter(v =>
        v.variant.id == value.variantId && v.variant.price === null || v.variant.price === undefined
      ).length > 0
    ).length > 0
  }


  /**
   * Calculates the price of a given configuration, keeping the current setting of installation service in mind
   */
  public queryPriceForConfiguration(config: Configuration, woodType?: WoodType): number {
    const variants = this._dataService.allVariantsForWoodType(woodType ?? config.configurationPlacement.woodType);
    const basePrice = this._calculateBasePrice(config, variants);
    const [cheapestWoodType, cheapestBasePrice] = this._cheapestWoodType(config);
    if (this._lastKnownConfiguration?.installationService) {
      return this._roundPrice(basePrice + this._getInstallationPrice(cheapestBasePrice, cheapestWoodType));
    } else {
      return this._roundPrice(basePrice);
    }
  }

  /**
   * Get the latest installation price
   */
  public getCurrentInstallationPrice(): number {
    const [cheapestWoodType, cheapestBasePrice] = this._cheapestWoodType(this._lastKnownConfiguration);
    return this._getInstallationPrice(cheapestBasePrice, cheapestWoodType);
  }

  /**
   * Calculates the installation price given a configuration price and a woodType
   */
  private _getInstallationPrice(configPrice: number, woodType: WoodType): number {
    const pricesArray = Array.from(this._dataService.currentRetailerOptions?.installationPrices?.get(woodType) ?? new Map<number, number>());
    return calculateInstallationPrice(configPrice, new Map(pricesArray.map(v => [v[0], {price: v[1]}])))?.price;
  }

  /**
   * Simple rounding function
   */
  private _roundPrice(price: number): number {
    return Math.round(price * 100) / 100;
  }

  public priceFormatter(price: number): string {
    const currency: string = this._dataService.currentRetailerOptions?.currencyOptions.currency || DEFAULT_CURRENCY;
    const locale: string = (this._dataService.currentRetailerOptions?.currencyOptions?.locale || DEFAULT_LOCALE).replace("_", "-");

    const options: Intl.NumberFormatOptions = this._dataService.currentRetailerOptions?.currencyOptions?.showCurrency ?
      {
        style: 'currency',
        currency,
        useGrouping: this._dataService.currentRetailerOptions?.currencyOptions?.showThousandsSeparator
      } :
      {
        minimumFractionDigits: 2,
        useGrouping: this._dataService.currentRetailerOptions?.currencyOptions?.showThousandsSeparator
      };

    return `${new Intl.NumberFormat(locale, options).format(price)}`;
  }
}
